import React from 'react';
import { compose } from 'recompose';

import DashboardWrapper from '../../components/Dashboard/DashboardWrapper';
import Layout from '../../components/layout';
import CardsSupportDocumentsNew from '../../components/Sections/CardsSupportDocumentsNew';
import {
  withAuthorization,
} from '../../components/Session';
import HeadData from "../../data/HeadData"
import BackButton from '../../components/Common/BackButton';

const SalesSupportDocumentsPageBase = () => (
  <DashboardWrapper pageName='Sales Support Documents'>
    <div className="relative pt-4 pb-32 md:pt-12 2xl:pt-20 md:px-8 px-0">
      <div className="container mx-auto px-4">
        <CardsSupportDocumentsNew />

        <BackButton buttonLink='/dashboard' />
      </div>
    </div>
  </DashboardWrapper>
);

const condition = authUser => !!authUser;

const SalesSupportDocumentsPage = compose(
  withAuthorization(condition),
)(SalesSupportDocumentsPageBase);

const SalesSupportDocuments = () => (
  <Layout>
    <HeadData title='Sales Support Documents' />
    <SalesSupportDocumentsPage />
  </Layout>
)

export default SalesSupportDocuments
