import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';

import PortalCard from '../Common/PortalCard';
import DocumentCard from '../Common/DocumentCard';
import DocumentCardsContainer from '../Common/DocumentCardsContainer';

const CardsSupportDocumentsNew = () => {
  const data = useStaticQuery(graphql`
    query CardsSupportDocumentsNewQuery {
      prismicSalesSupportDocuments {
        data {
          section_title
          pdf_files_list {
            pdf_file_title
            pdf_download_link {
              url
            }
          }
        }
      }
      prismicSalesSupportDocumentsPowerpoint {
        data {
          description
          button_text
          card_background_image {
            gatsbyImageData(
              width: 750
            )
          }
        }
      }
      prismicSalesSupportDocumentsProductCollateral {
        data {
          description
          button_text
          card_background_image {
            gatsbyImageData(
              width: 750
            )
          }
        }
      }
    }
  `);

  const doc = data.prismicSalesSupportDocuments
  const doc2 = data.prismicSalesSupportDocumentsPowerpoint
  const doc3 = data.prismicSalesSupportDocumentsProductCollateral
  const imgPowerpointPresentations = getImage(doc2.data.card_background_image);
  const imgProductCollateral = getImage(doc3.data.card_background_image);

  return (
    <>
      <div className="grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-3 gap-6 xl:gap-12 mt-16">
        <PortalCard
          cardImg={imgPowerpointPresentations}
          cardText={doc2.data.description}
          cardButtonLink="/sales-support-documents/powerpoints"
          cardButtonText={doc2.data.button_text}
        />
        <PortalCard
          cardImg={imgProductCollateral}
          cardText={doc3.data.description}
          cardButtonLink="/sales-support-documents/collateral"
          cardButtonText={doc3.data.button_text}
        />
      </div>

      <DocumentCardsContainer containerTitle={doc.data.section_title}>
        {doc.data.pdf_files_list.map(item => {
          return (
            <DocumentCard
              cardTitle={item.pdf_file_title}
              buttonLink={item.pdf_download_link.url}
            />
          )
        })}
      </DocumentCardsContainer>
    </>
  );
};

export default CardsSupportDocumentsNew;
